import React from 'react'

class GoedDoel extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            src: this.props.src,
            name: this.props.value,
            text: this.props.text,
            selected: this.props.selected,
            link: this.props.link
        }
    }

    render() {
        return (
            <label className="goed_doel_card">
                <img
                    src={this.state.src}
                    alt={this.state.name}
                    className="goed_doel_logo"
                ></img>
                <input
                    className="radio select"
                    type="radio"
                    name="goed_doel"
                    value={this.state.name}
                    selected={this.state.selected}
                    required="required"
                    onChange={this.props.handleChange}
                ></input>
                <input
                    type="checkbox"
                    class="read-more-state"
                    id={this.state.name}
                />
                <div class="read-more-wrap">
                    <p class="read-more-target">
                        {this.state.text}
                        <a
                            href={this.state.link}
                            target="_blank"
                            rel="noreferrer"
                        >
                            Meer informatie
                        </a>
                    </p>
                </div>
                <label for={this.state.name} class="read-more-trigger"></label>
                <span></span>
            </label>
        )
    }
}
export default GoedDoel
