import React from 'react'
import FormKerst from './form_kerst'
import '../card.css'
import './snow'

class Card extends React.Component {
    render() {
        return (
            <div className="card">
                <div className="card__logo">
                    <img src="./lug-logo.png" alt=""></img>
                </div>
                <FormKerst />
            </div>
        )
    }
}

export default Card
