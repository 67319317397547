import React from 'react'
import { GoogleSpreadsheet } from 'google-spreadsheet'
import GoedDoel from './goed_doel_card'
import creds from './sheets.json'

//console.log('ENV:', process.env.REACT_APP_SPREADSHEET_ID)
class FormKerst extends React.Component {
    constructor(props) {
        super()
        this.state = {
            email: '',
            goed_doel: '',
            error_message: false,
            submitted: false
        }
    }

    handleChange(event) {
        const target = event.target
        const value = target.value
        const key = target.name
        this.setState({ [key]: value })
    }

    appendSpreadsheet = async row => {
        //   console.log('Sturen:', row)
        const doc = new GoogleSpreadsheet(process.env.REACT_APP_SPREADSHEET_ID)

        try {
            await doc.useServiceAccountAuth(creds)
            // loads document properties and worksheets
            await doc.loadInfo()

            const sheet = doc.sheetsById[process.env.REACT_APP_SHEET_ID]
            await sheet.addRow(row)
        } catch (e) {
            console.error('Error: ', e)
        }
    }

    handleSubmit() {
        if (this.state.email === '' || this.state.goed_doel === '') {
            console.log('invalid')
            this.setState({ error_message: true })
        } else {
            const newRow = {
                email: this.state.email,
                goed_doel: this.state.goed_doel
            }
            this.appendSpreadsheet(newRow)
            this.setState({ submitted: true })
        }
    }
    render() {
        return (
            <div className="formDiv">
                <div className="goede_doelen">
                    <div className="goede_doelen__title">
                        Doneer op onze kosten aan een goed doel uit onderstaande
                        lijst.
                    </div>
                    <div className="goede_doelen__subtitle">
                        Klik hieronder op het doel dat je voorkeur heeft, vul
                        onderaan je email en wij zorgen voor de donatie!
                    </div>
                    <div className="goede_doelen__cardcontainer">
                        <GoedDoel
                            handleChange={this.handleChange.bind(this)}
                            src="./kika-logo.png"
                            value="Kika"
                            text="Vroeg of laat krijgen we allemaal te maken krijgen met kanker. Wanneer het een kind betreft, is het echter niet te bevatten wat voor impact dit heeft op het kind zelf, de omgeving, de ouders en de toekomst. Hopelijk zijn we het erover eens dat een hogere overlevingskans voor zulke jonge mensen met een heel leven voor zich essentieel is! Wat mij betreft is Kika een waardevol initiatief om uiteindelijk kids te helpen die weer naar school kunnen, kunnen spelen en dromen over de toekomst. Vandaar dat ik graag een bijdrage voor Kika vraag."
                            selected=""
                            link="https://kika.nl/"
                        />
                        <GoedDoel
                            handleChange={this.handleChange.bind(this)}
                            src="./savethechildren-logo.png"
                            value="Save the Children (Hulp voor kinderen in Gaza en Israel)"
                            text="Wat alle Israëlische en Palestijnse kinderen nu meemaken, zou geen enkel kind mee moeten maken. De leefomgeving is zó onveilig en ongezond dat er eigenlijk geen woorden voor zijn. Het zou je eigen kind maar zijn die daartussen zit… die generatie bevat onze toekomstige artsen, vuilnismannen en zorgverleners, en wat zij nu meekrijgen kenmerkt hen voor de rest van hun leven. Save the Children kiest geen partij in dit conflict maar helpt álle kinderen die in dit conflict terecht zijn gekomen. Ik vind het belangrijk dat we hulp bieden aan onschuldige slachtoffers, ongeacht aan welke kant van de grens hun bed staat."
                            selected=""
                            link="https://www.savethechildren.nl/pagina/gaza-israel-conflict "
                        />
                        <GoedDoel
                            handleChange={this.handleChange.bind(this)}
                            src="./riforest-logo.png"
                            value="Riforest"
                            text="Persoonlijk ben ik sinds kort betrokken als donateur en vrijwilliger bij Stichting Riforest. Zij helpt om ontbossing en landerosie tegen te gaan in Noord-Marokko. Concreet zet Stichting Riforest zich in voor het bieden van duurzame sociaaleconomische ontwikkeling en het tegengaan van bodemerosie door boerenfamilies perspectief te bieden middels het helpen aanplanten van nieuwe bomen, struiken, kruiden en andere gewassen die ecologisch herstel bevorderen. Deze regio heeft te maken met diverse ecologische en klimaat gerelateerde uitdagingen die ook sociaaleconomische impact hebben. Riforest is erin geslaagd om duurzame banden te leggen met lokale boeren en gemeenschappen en heeft meer dan 30 dorpen geholpen met het aanplanten van 105.000 bomen waar 650 gezinnen op de lange termijn mee geholpen zijn door meer groen, meer duurzame oogst en meer natuur in hun leefomgeving te hebben. Graag wil ik de stichting helpen met een donatie om zo haar duurzame werk door te kunnen zetten en een nog grotere positieve impact te kunnen maken. "
                            selected=""
                            link="https://www.riforest.nl/ "
                        />
                        <GoedDoel
                            handleChange={this.handleChange.bind(this)}
                            src="./hospitaalbroeders-logo.jpg"
                            value="HospitaalBroeders"
                            text="Hoe meer ik mij verdiep in de activiteiten van HospitaalBroeders, hoe meer ik vind dat ze meer donaties verdienen. HospitaalBroeders zet zich in voor een goede gezondheidszorg in Afrika. Ze zetten ziekenhuizen neer op plekken waar te weinig zorg is, leiden kansarme jongeren op tot verplegend personeel en zetten Outreams teams in die naar afgelegen gebieden gaan om mensen te helpen die niet naar ziekenhuizen kunnen komen. Zo voorzien zij de allerarmste van vaccinaties, voedsel, opvang, voorlichting, medische assistentie en acute hulp. De zwaksten in de samenleving krijgen zo de zorg die zij verdienen. Dit doet HospitaalBroeders o.a. in Kenia, Malawi, Sierra Leone, Ghana, Kameroen. Met een klein groepje hebben ze al 19 ziekenhuizen opgezet waar 2.000 mensen werkgelegenheid vonden.
                            Vanuit mijn gratis MVO uren bij Netprofiler maak ik content voor HospitaalBroeders. Dan realiseer ik me hoe goed we het hebben in Nederland en hoe slecht het op sommige plekken in de wereld gaat. Ik zou willen dat ik meer kon doen dan content maken en donaties geven. En dit is mijn kans om hen écht te helpen. Samen met LUG. Ik hoop dat wij een verschil kunnen maken! Als kleine NGO met maar 4 medewerkers in Nederland, kunnen ze onze hulp goed gebruiken."
                            selected=""
                            link="https://www.hospitaalbroeders.nl/wat-we-doen"
                        />
                        <GoedDoel
                            handleChange={this.handleChange.bind(this)}
                            src="./stichtinglongcovid-logo.png"
                            value="Stichting Longcovid "
                            text="Zo’n anderhalf jaar geleden startte een nieuwe collega binnen LevelUp Group. Vanwege Long Covid klachten begon hij met een inzet van 50% wat hij (en wij) langzaam wilde opbouwen naar een grotere inzet. In de praktijk bleek echter dat hij zich niet goed meer kon concentreren, extreem vermoeid was na een meeting en zodoende is hij gestopt met zijn werk. Zoals deze collega zijn er nog 10.000’en andere Long Covid patiënten in Nederland die nog dagelijks worstelen met de gevolgen van Long Covid. Er is echter nog weinig bekend over de genezing van Long Covid en daar moet verandering in komen. Daarom wil ik graag Stichting Longcovid ondersteunen omdat ze geld  
                            Inzamelen voor onderzoek naar genezing van Long Covid. De eerste vier onderzoeken zijn dit jaar al gestart en ze zijn op zoek naar donaties om volgende onderzoeken te kunnen starten."
                            selected=""
                            link="https://www.stichtinglongcovid.nl/  "
                        />
                        <GoedDoel
                            handleChange={this.handleChange.bind(this)}
                            src="./100weeks-logo.png"
                            value="100WEEKS"
                            text="Door vrouwen in Afrika gedurende 100 weken 8 euro per week te geven in combinatie met financiële coaching, helpt 100WEEKS hen om voorgoed uit de armoede te kunnen komen. Ze hebben hiermee al meer dan 3.000 vrouwen een mooie toekomst kunnen bieden. De methode van 100WEEKS is eenvoudig en bewezen effectief. De 100 weken financiële support geeft de vrouwen de kans om op eigen wijze uit de armoede te komen. Doordat ze niet meer elke dag hoeven te focussen op overleven, wordt het mogelijk naar de toekomst te kijken. Juist omdat ontvangers weten dat er aan de wekelijkse betalingen een einde komt, grijpen ze deze kans aan om te investeren in de toekomst. De deelnemers bepalen zelf hoe ze het geld besteden. 100WEEKS biedt hen financiële coaching en training in ondernemersvaardigheden. Ook komen de vrouwen in het programma samen in spaargroepen. Dit Nederlandse goede doel verdient onze steun omdat het bewezen effectief is in armoedebestrijding!"
                            selected=""
                            link="https://100weeks.nl/"
                        />
                        <GoedDoel
                            handleChange={this.handleChange.bind(this)}
                            src="./legerdesheils-logo.png"
                            value="Leger des Heils"
                            text="Het Leger des Heils biedt hulp aan mensen in nood en in kwetsbare situaties. Dit doen ze op diverse manieren. Zo zorgen ze voor de opvang en ondersteuning aan daklozen en verslaafden. Daarnaast helpen ze mensen in armoede door het verstrekken van voedsel, kleding en andere basisbehoeften aan mensen in armoede. Ook biedt Het Leger des Heils biedt maatschappelijke dienstverlening, waaronder counseling, hulp bij schulden, gezinsbegeleiding, reclassering, jeugdhulp en buurthulp. In 2022 zijn 114.636 mensen door het Leger des Heils ondersteund waarbij 3,6 miljoen overnachtingen zijn verzorgd voor daklozen en thuislozen waaronder veel Oekraïense vluchtelingen. Verder zijn 11,1 miljoen maaltijden verstrekt en hebben 11.400 vrijwilligers zich ingezet. De waardevolle activiteiten van het Leger des Heils verdienen onze steun dus help hen om dit goede werk voor te zetten!"
                            selected=""
                            link="https://www.legerdesheils.nl/"
                        />
                        <GoedDoel
                            handleChange={this.handleChange.bind(this)}
                            src="./oxfamnovib-logo2.jpg"
                            value="Oxfam Novib"
                            text="Oxfam Novib zet zich in voor armoedebestrijding en sociale rechtvaardigheid door bieden van ontwikkelingssamenwerkingen humanitaire hulp. Dit goede doel staat achter de mensen die opstaan voor gelijkheid. Mensen die op hun eigen manier strijden tegen de groeiende verschillen. Tegen onrecht. Tegen klimaatverandering, dat de meest kwetsbaren het hardst raakt. Mensen die opkomen voor eerlijke lonen. Voor het recht zichzelf te kunnen zijn.  
                            Oxfam Novib doet dit door te werken aan een wereld die inclusief, veilig, rechtvaardig en duurzaam is. Een wereld die voor iedereen veilig is; waarin de inkomenskloof krimpt in plaats van groeit; waar klimaatverandering harder en eerlijker wordt aangepakt. Sta op en doe mee. Samen strijden we voor gelijkheid. Door Oxfam Novib te steunen help je de mensen die strijden voor gelijke rechten voor iedereen.   "
                            selected=""
                            link="https://www.oxfamnovib.nl/"
                        />
                        <GoedDoel
                            handleChange={this.handleChange.bind(this)}
                            src="./worldvision-logo.png"
                            value="World Vision"
                            text="World Vision zet zich in voor het welzijn van kinderen in ontwikkelingslanden door het verbeteren onderwijs, gezondheidszorg, voeding en bescherming tegen misbruik en uitbuiting. World Vision streeft naar duurzame ontwikkeling door het ondersteunen van gemeenschappen bij het opbouwen van hun eigen capaciteiten en veerkracht. Ze helpt waar kinderen en families in nood zijn. In ontwikkelingslanden komt de bevolking veel te kort: water, voedsel, medische zorg, opleiding en inkomen. Er zijn speciale kindsponsoringprogramma’s waarbij je als sponsor kan schrijven en e-mailen naar jouw sponsorkind. Je ontvangt dan foto’s van jouw sponsorkind en van de gemeenschap waar hij/zij woont. Wereldwijd worden er via World Vision maar liefst 3,2 miljoen kinderen gesponsord! Gun kinderen in ontwikkelingslanden ook een mooier leven en steun World Vision!  "
                            selected=""
                            link="https://www.worldvision.nl/"
                        />
                        <GoedDoel
                            handleChange={this.handleChange.bind(this)}
                            src="./hartstichting.svg"
                            value="Hartstichting"
                            text="Er zijn nu 1,7 miljoen hart- en vaatpatiënten in Nederland. En dat aantal neemt sterk toe. Het doel van de Hartstichting is om het aantal mensen dat getroffen wordt door hart- en vaatziekten te verminderen, de overlevingskansen te vergroten en de kwaliteit van leven van mensen met hartproblemen te verbeteren. Door wetenschappelijk onderzoek dat de Hartstichting ondersteunt, komen er betere preventiemethoden en behandelingen. Daarnaast wil de Hartstichting door het geven van informatie over een gezonde levensstijl hart- en vaatziekten beter voorkomen. De informatie omvat educatieve programma's, voorlichtingsmateriaal en campagnes om mensen bewust te maken van risicofactoren en symptomen. Help mee aan een gezonder Nederland en kies voor de Hartstichting!"
                            selected=""
                            link="https://www.hartstichting.nl/over-ons"
                        />
                        <GoedDoel
                            handleChange={this.handleChange.bind(this)}
                            src="./amnesty-logo.svg"
                            value="Amnesty International"
                            text="‘Pas als de laatste gewetensgevangene is vrijgelaten, als de laatste martelkamer is gesloten, als de Universele Verklaring van de Rechten van de Mens een realiteit is voor de wereldbevolking, zit ons werk erop.’ Dat zijn de woorden van Peter Benenson, oprichter van Amnesty International. Deze een wereldwijde mensenrechtenorganisatie zet zich in voor de bevordering en verdediging van mensenrechten over de hele wereld. Belangrijke kwesties waar ze zich op richt zijn strijden voor de rechten van politieke gevangenen, afschaffing van de doodstraf, opkomen voor de vrijheid van meningsuiting en strijden tegen politiek geweld. Dit doet ze door het onderzoeken en aan de kaak stellen van mensenrechtenschendingen, het indienen van juridische petities, het ondersteunen van juridische zaken, het voeren campagnes om bewustwording te creëren over specifieke kwesties door te lobbyen en diplomatie met overheden en internationale organisaties. Stop onrecht en steun Amnesty."
                            selected=""
                            link="https://www.amnesty.nl/wie-we-zijn"
                        />
                        <GoedDoel
                            handleChange={this.handleChange.bind(this)}
                            src="./ub-logo.png"
                            value="Stichting Ub!"
                            text="Inmiddels groeit 1 op de 9 kinderen in Nederland op in armoede. En heeft 1 op de 12 kinderen problemen thuis. De hulpvragen stapelen zich op. Stichting Ub! is een beweging gestart om zoveel mogelijk mensen en bedrijven in actie te krijgen om kwetsbare kinderen te helpen. Veel hulpvragen van kwetsbare kinderen in Nederland vallen tussen wal en schip. Steeds meer kinderen lijden onder problemen thuis en komen in de knel. Dat moet en kan anders! Stichting Ub! koppelt hulpvragen van kwetsbare kinderen en jongeren aan vrijwilligers die willen helpen. Ub! werkt daarbij samen met jeugdhulporganisaties, want hun hulpverleners weten als geen ander welke steun keihard nodig is. Ze organiseert activiteiten en werft vrijwilligers om kwetsbare kinderen en jongeren concreet te helpen, met een klein of een groot gebaar. Bijvoorbeeld met het zoeken van een reismaatje zodat Mike in het weekend naar huis kan. Een nieuwe fiets voor Michael zodat hij zijn vader kan opzoeken. Een huiswerkmaatje voor Jordy of een taalmaatje voor Nadia zodat ze niet verder achterraken op school. Het doel van Stichting Ub!: zo veel mogelijk kwetsbare kinderen en jongeren helpen door zo veel mogelijk mensen en bedrijven in actie te krijgen! Het afgelopen jaar heeft Stichting Ub! 700 kinderen kunnen helpen met de inzet van 500 vrijwilligers. Dat is fantastisch, maar er zijn nog veel meer kinderen die hulp nodig hebben. Met jouw steun kan Stichting Ub! nog meer hulpvragen invullen en uiteindelijk groeien om in heel Nederland kwetsbare kinderen en jongeren te helpen."
                            selected=""
                            link="https://stichtingub.nl/"
                        />
                        <GoedDoel
                            handleChange={this.handleChange.bind(this)}
                            src="./jeugdfonds-logo.svg"
                            value="Jeugdfonds Sport en Cultuur"
                            text="Door de stijgende inflatie hebben steeds meer gezinnen moeite om rond te komen. 1 op de 9 (!) kinderen in Nederland groeit nu al op in armoede wat regelmatig leidt tot veel problemen bij kinderen. Denk aan eetstoornissen, depressie, verslaving, slechter eten, minder bewegen en minder sociale ontwikkeling. Vaak met overgewicht en andere langdurige gezondheidsklachten als gevolg. En dit juist bij hen die toch al minder kansen krijgen. Het jeugdfonds Sport en Cultuur helpt kinderen uit gezinnen met weinig geld door de contributie en de benodigde attributen voor een sport of cultuurclub voor deze kinderen te betalen. Door kinderen mee te laten draaien met sport en cultuurclubs heeft een kind een positieve uitlaatklep, een grotere sociale omgeving en gezonde beweging. Binnen de LevelUp Group zijn we altijd bezig met de lichamelijke en geestelijk gezondheid van onze medewerkers. Ik vind het belangrijk dag we de jongste deelnemers van de maatschappij ook een goede en gezonde basis kunnen geven in het leven, juist nu! En dat doen we door het steunen van het Jeugdfonds Sport en Cultuur!"
                            selected=""
                            link="https://jeugdfondssportencultuur.nl/"
                        />
                        <GoedDoel
                            handleChange={this.handleChange.bind(this)}
                            src="./oorfonds.png"
                            value="Oorfonds"
                            text="Je zal het niet zo snel merken, maar ik ben een van de mensen met een ooraandoening. Ik heb namelijk tinnitus: in mijn geval is dat een constante piep in mijn oor. Dit komt niet doordat ik flink heb lopen feesten naast een geluidsbox, maar nadat ik een tijdje flink ziek ben geweest. Het was er ineens en is nooit meer weg gegaan. De dokter zei destijds ‘je moet er maar mee leren leven’ en kon me verder nergens mee helpen. En ik ben lang niet de enige. Tinnitus is een van de aandoeningen waar het Oorfonds onderzoek naar wil doen, omdat we er nog zo weinig over weten. En omdat niets zo fijn zou zijn als weer echt de stilte te kunnen horen. Het Oorfonds heeft als missie alle oorproblemen de wereld uit te helpen. Het fonds is in 2019 opgericht op een initiatief van mensen die zelf te maken hebben met een ooraandoening omdat gerichte fondswerving voor ooronderzoek nog niet bestond. Ik denk dat we met zijn allen kunnen beamen dat horen een van de belangrijkste dingen in het leven is. En dat we hier vaak te weinig bij stil staan."
                            selected=""
                            link="https://www.oorfonds.nl/"
                        />
                        <GoedDoel
                            handleChange={this.handleChange.bind(this)}
                            src="./stoelenproject-logo.jpeg"
                            value="Het Stoelenproject"
                            text="Een vriendin van me is één van de vaste chefs en vrienden van het Stoelenproject. Dit is de meest laagdrempelige avond- en nachtopvang van Amsterdam. De opvang is eenvoudig, veilig, warm en geheel gratis! Elke avond en nacht verblijven er 50 daklozen in deze opvang aan de Marnixstraat in Amsterdam. De opvang is alleen open in de koudere periode van het jaar en zorgt jaarlijks voor circa 12.000 overnachtingen met 90 vrijwilligers. De stichting heeft geld nodig voor gezonde en lekkere warme maaltijden voor de bezoekers, maar ook voor matrassen en kookspulletjes. Het is niet vanzelfsprekend dat iedereen een warme maaltijd en een warm bed heeft. Laten we onze weelde daarom terugvloeien naar andere Amsterdammers die het harder nodig hebben dan wij."
                            selected=""
                            link="https://www.stoelenproject.nl/"
                        />
                        <GoedDoel
                            handleChange={this.handleChange.bind(this)}
                            src="./becausewecarry-logo.png"
                            value="Because we carry"
                            text="Because We Carry steunt zwangere vrouwen en moeders op de vlucht om hun afschuwelijke situatie te verlichten. Elke moeder wil het beste voor haar kind. Als je gevlucht bent is dat niet ineens anders. Alleen, in een vreemd land, met van alles te weinig is het moeder zijn echt heftig. Dan heb je gewoon ondersteuning nodig. Because We Carry helpt deze (aanstaande) moeders met begeleiding en praktische hulp zoals kinderwagens, kleren, luiers en eten. Met een vast team op Lesbos en elke week wisselende teams van vrijwilligers steunt Because We Carry de vrouwen met wat er het hardste nodig is. Voor, tijdens en na de zwangerschap, voor kleine en iets grotere kinderen."
                            selected=""
                            link="https://becausewecarry.org/"
                        />
                        <GoedDoel
                            handleChange={this.handleChange.bind(this)}
                            src="./stichtingopkikker-logo.svg"
                            value="Stichting Opkikker"
                            text="Ik heb zelf als kind het geluk gehad om een Opkikkerdag van Stichting Opkikker te mogen ervaren in een periode dat ik veelvoudig in het ziekenhuis te vinden was. Stichting Opkikker richt zich namelijk op het organiseren van speciale belevenisdagen voor gezinnen met langdurig zieke kinderen. Zo organiseren ze Opkikkerdagen, waarbij gezinnen de kans krijgen om even te ontsnappen aan de dagelijkse zorgen rond de ziekte van het kind. Tijdens deze dag, die helemaal is gebaseerd op de wensen en interesses van alle gezinsleden, kunnen ze genieten van diverse leuke en ontspannende activiteiten. Deze dagen zijn bedoeld om de gezinnen positieve energie te geven en ze te laten genieten van quality time samen. Ik heb persoonlijk mogen meemaken hoe bijzonder en waardevol deze dagen zijn, niet alleen voor het zieke kind zelf, maar vooral de hele familie, voor wie deze periode vaak nog zwaarder is. Het is een ervaring die ik nog steeds koester en die diepe indruk op me heeft gemaakt. Door een donatie te geven, dragen we bij aan het voortzetten van deze prachtige Nederlandse traditie en kunnen meer gezinnen de kracht van een Opkikkerdag ervaren."
                            selected=""
                            link="https://opkikker.nl/"
                        />
                        <GoedDoel
                            handleChange={this.handleChange.bind(this)}
                            src="./oogfonds-logo.svg"
                            value="Oogfonds"
                            text="Iedereen kan plots last krijgen van zijn ogen en zijn zicht. Zeven jaar geleden overkwam mij dit plotseling ook en ik gebruik nu medicatie om ervoor te zorgen dat ik niet blind word. Je ogen zijn namelijk erg gevoelig en oogklachten komen vaak voor. Daarom is het goed dat het Oogfonds er is want zij financiert wetenschappelijk onderzoek naar het voorkomen en behandelen van oogproblemen. Ook steunt de stichting patiëntenverenigingen in het verbeteren van de kwaliteit van leven voor mensen met een visuele beperking. Onze ogen zijn ons favoriete zintuig! Ze laten ons zien, beleven, bewonderen en beschermen ons. En pas als ze ons in de steek laten, beseffen we de onschatbare waarde van zien. Steun daarom het Oogfonds."
                            selected=""
                            link="https://oogfonds.nl/"
                        />
                        <GoedDoel
                            handleChange={this.handleChange.bind(this)}
                            src="./natuurmonumenten-logo.svg"
                            value="Natuurmonumenten"
                            text="Al een paar jaar ben ik zelf donateur van Natuurmonumenten omdat ik vind dat ze heel waardevol en mooi werk doen. Ze focust zich op het herstel van de biodiversiteit: zowel van het aantal soorten planten en dieren, als van de hoeveelheid per soort. In samenwerking met boeren, bedrijven en burgers werken ze aan duurzame oplossingen die de biodiversiteit versterken. En herstel van de biodiversiteit is hard nodig. In nog geen 30 jaar is bijvoorbeeld maar liefst 76% van de insecten in Nederland verdwenen. De natuur is belangrijk voor onze gezondheid en bestaanszekerheid en daarom vind ik dat we ons daarvoor moeten inzetten."
                            selected=""
                            link="https://www.natuurmonumenten.nl/"
                        />
                        <GoedDoel
                            handleChange={this.handleChange.bind(this)}
                            src="./vluchtelingenwerk-logo.svg"
                            value="VluchtelingenWerk"
                            text="Met alle oorlogsslachtoffers, en problematiek met het opvangen van mensen op dit moment in bijvoorbeeld Ter Apel, zou het heel fijn zijn als VluchtelingenWerk steun van ons allemaal krijgt. VluchtelingenWerk zelf ondersteunt asielzoekers en vluchtelingen van het moment dat ze worden opgevangen in Nederland totdat ze zelfstandig hun weg hebben gevonden. Dat is nu urgent en daarom is het goed om VluchtenlingenWerk te steunen."
                            selected=""
                            link="https://www.vluchtelingenwerk.nl/"
                        />
                    </div>
                </div>
                {!this.state.submitted && (
                    <div className="address">
                        {this.state.error_message && (
                            <div className="error">
                                Vul a.u.b. je emailadres in en selecteer een
                                goed doel!
                            </div>
                        )}
                        <label>Je emailadres (voor bevestiging)</label>

                        <div className="address__input_container">
                            <input
                                className="input email"
                                type="text"
                                name="email"
                                required="required"
                                onChange={this.handleChange.bind(this)}
                            ></input>

                            <input
                                className="button green submit"
                                type="submit"
                                value="Stem"
                                onClick={this.handleSubmit.bind(this)}
                            ></input>
                        </div>
                    </div>
                )}
                {this.state.submitted && (
                    <div className="goede_doelen__subtitle succes">
                        Dank voor het doorgeven van jouw voorkeur.
                    </div>
                )}
            </div>
        )
    }
}

export default FormKerst
